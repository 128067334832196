import { get } from "@vueuse/core";

const getRoute = (args: {
  endpont: string;
  id?: string | number | undefined;
  rest?: string | number | undefined;
}) => {
  const apiMap: { [key: string]: string } = {
    get_user_by_id: `/api/users/${args.id}`,
    post_exchange: "/api/users/claim/tokens",
    get_leaderbord_info: "/api/leaderboard/info",
    get_leaderbord: "/api/leaderboard",
    get_user_referral_link: "/api/users/referral/link",
  };
  return apiMap[args.endpont];
};

export default getRoute;
