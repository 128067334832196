import { serializeObject } from '~/utilities/helpers/serializeObject';

interface ApiFunctionArgs {
  url: string;
  query?: Record<string, any>;
  body?: Record<string, any>;
  headers?: Record<string, any>;
  imageFile?: string | Blob | undefined;
}

export const apiGet = async ({ url, headers, query }: ApiFunctionArgs) => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.baseURL as string;
  const setUrl = baseUrl + url;
  let queryString = "";
    if (query) {
      queryString = serializeObject(query);
    }

  return $fetch(setUrl + (queryString ? `?${queryString}` : ""), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });
};

export const apiPost = async ({ url, body, headers }: ApiFunctionArgs) => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.baseURL as string;
  return $fetch(`${baseUrl + url}`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};
